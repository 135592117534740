/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-22 15:40:27
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-04-25 11:49:29
 */
export default {
  students: '/course/manage/students/list',
  classList: '/course/manage/students/classList',
  studentRemove: '/course/manage/students/remove',
  studentCreate: '/course/manage/student/create',
  studentInfo: '/course/manage/student/info',
  studentUpdate: '/course/manage/student/info/update',
  studentImport: '/course/manage/class-student/import',
  studentTemplate: '/course/manage/class-stduent/template/export', // 下载导入模板
  studentInvite: '/course/manage/org/inviteCode',
}
