<!--
 * @Description: 批量添加
 * @Author: xiawenlong
 * @Date: 2021-04-22 15:03:59
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-10-09 10:55:34
-->
<template>
  <div class="student-batch-add">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">批量添加员工</span>
      </div>
      <el-upload
        class="upload-batch"
        drag
        action=""
        :auto-upload="false"
        :file-list="fileList"
        :on-change="handleChange"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div slot="tip" class="el-upload__tip">
          <div class="btns">
            <el-button :loading="loading" type="primary" @click="importSubmit">确定导入</el-button>
            <el-button @click="downloadTemplate">下载导入模板</el-button>
          </div>
          <p>模版说明：</p>
          <p>员工姓名：员工的真实姓名。</p>
          <p>身份证/护照：员工对应的18位身份证号、外籍员工填写护照编号。</p>
          <p>员工手机号：员工的手机号。</p>
          <p>部门（选填）：员工在书院对应的部门。</p>
          <!-- <p>加入班级（选填）：填写后导入学员自动添加进入班级。</p> -->
        </div>
      </el-upload>
    </el-card>
  </div>
</template>
<script>
import { studentTemplate, studentImport } from '@/api/student'
import to from 'await-to'
export default {
  name: 'StudentBatchAdd',
  data() {
    return {
      loading: false,
      fileList: [],
      deptId: this.$route.query.deptId,
    }
  },
  methods: {
    async downloadTemplate() {
      const [res, err] = await to(studentTemplate())
      if (err) return this.$message.warning(err.msg)
      const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
      if (window.navigator.msSaveOrOpenBlob) {
        //兼容IE10
        navigator.msSaveBlob(blob, this.filename)
      } else {
        const href = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = href
        a.download = '导入模板'
        a.click()
        URL.revokeObjectURL(a.href)
      }
    },
    handleChange(file, fileList) {
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.fileList = []
        return this.$message.warning('上传文件大小不能超过 5MB!')
      }
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]
      }
      var extName = file.name.substring(file.name.lastIndexOf('.')).toLowerCase()
      var AllUpExt = '.xls|.xlsx|'
      if (AllUpExt.indexOf(extName + '|') === -1) {
        this.fileList = []
        this.$message.warning('仅支持上传excel文件，请重新上传!')
      }
    },
    async importSubmit() {
      if (this.loading) return
      if (!this.fileList.length) return this.$message.warning('请先选择文件')
      let formData = new FormData()
      if (this.deptId == undefined) {
        this.deptId = ''
      }
      formData.append('file', this.fileList[0].raw)
      formData.append('deptId', this.deptId)
      this.loading = true
      const [, err] = await to(studentImport(formData))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.$message.success('导入成功')
      this.$router.push('/student/department')
    },
  },
}
</script>
<style lang="scss" scoped>
.student-batch-add {
  .title {
    font-size: 18px;
    font-weight: 500;
    color: #4d4e5d;
    margin-bottom: 20px;
  }
  ::v-deep.upload-batch {
    width: 466px;
    margin: 0 auto;
    position: relative;
    .el-upload-list {
      position: absolute;
      width: 100%;
      left: 0;
      top: 290px;
    }
    .el-upload {
      &__tip {
        font-size: 14px;
        color: #333333;
        line-height: 22px;
        .btns {
          padding: 0 60px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 35px;
        }
      }
      .el-upload-dragger {
        width: 466px;
        height: 298px;
        background: #f5f5f5;
        border-radius: 10px;
        margin-bottom: 20px;
        padding-top: 60px;
        .el-icon-upload {
          font-size: 105px;
          margin-bottom: 26px;
        }
      }
    }
  }
  ::v-deep.el-card__body {
    padding: 30px;
  }
}
</style>
